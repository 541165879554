import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
function App() {
  return (
    <Router>
      <div className="App">
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/a">Page A</Link></li>
            <li><Link to="/b">Page B</Link></li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<h1>Hello</h1>} />
          <Route path="/a" element={<h1>a</h1>} />
          <Route path="/b" element={<h1>b</h1>} />
        </Routes>
      </div>
    </Router>
  );
}
export default App;
